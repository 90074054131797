<template>
  <div>
    <!-- 搜索栏 -->
    <search-bar class="search-bar" v-model="keyword" @search="handleSearch"></search-bar>

    <!-- 列表 -->
    <div class="list">
      <!-- 列表项 -->
      <div class="list-item flex-row-default-center" v-for="(item, index) in list" :key="index" @click="handleSwitchPage(item.infoID)">
        <!-- 图片 -->
        <img :src="item.mainPicture !== '' ? item.mainPicture : defaultImages[category]" :onerror="defaultImages[category]" />
        <!-- 内容 -->
        <div class="body flex-column-evenly-default">
          <!-- 标题 -->
          <div class="title one-line">{{ item.title }}</div>
          <!-- 阅读、点赞 -->
          <div class="content flex-row-between-center">
            <!-- 阅读 -->
            <div class="flex-row-default-center">
              <div class="read-count flex-center-center">
                <span class="iconfont icon-read"></span>
                <span class="one-line">{{item.readCount}}</span>
              </div>

              <!-- 点赞 -->
              <div class="favour-count flex-center-center">
                <span class="iconfont icon-favour"></span>
                <span class="one-line">{{item.favourCount}}</span>
              </div>
            </div>

            <!-- 时间 -->
            <div class="time">{{ item.createdAt.substring(0, 10) }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底标 -->
    <div class="no-more" v-if="isEnd">没有更多内容了</div>
  </div>
</template>

<script>
import page from '@/mixins/page'
import SearchBar from '@/components/SearchBar'

export default {
  name: 'DataList',

  props: {
    category: {
      type: String,
      required: true,
      default: ''
    },
    detailRouter: {
      type: String,
      default: '/'
    }
  },

  data () {
    return {
      defaultImages: {
        AssociationNews: require('@/assets/images/default_AssociationNews.png'),
        Creativity: require('@/assets/images/default_Creativity.png'),
        Policy: require('@/assets/images/default_Policy.png'),
        IndustryNews: require('@/assets/images/default_IndustryNews.png'),
        MemberNews: require('@/assets/images/default_MemberNews.png')
      },
      list: [],
      keyword: '',
      currentPage: 1,
      pageSize: 8,
      isEnd: false
    }
  },

  components: { SearchBar },

  mixins: [page],

  beforeCreate () {
    const images = [
      require('@/assets/images/default_AssociationNews.png'),
      require('@/assets/images/default_Creativity.png'),
      require('@/assets/images/default_IndustryNews.png'),
      require('@/assets/images/default_MemberNews.png'),
      require('@/assets/images/default_Policy.png')
    ]
    for (const src of images) {
      const image = new Image()
      image.src = src
    }
  },

  created () {
    this.pageSize = Math.ceil((this.getWindowHeight() - 30 - 32) / 95)
    this.getData()
  },

  beforeRouteLeave (to, from, next) {
    from.meta.keepAlive = false
    next()
  },

  methods: {
    // 查看详情
    handleSwitchPage (id) {
      this.$router.push({ path: this.detailRouter, query: { id, category: this.category } })
    },
    // 获取数据
    getData () {
      this.api.getAllInfo({ currentPage: this.currentPage, pageSize: this.pageSize, category: this.category, keyword: this.keyword }).then(res => {
        if (res.data.length === 0 || res.data.length < this.pageSize) this.isEnd = true
        this.list = [...this.list, ...res.data]
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getData() })
      })
    },
    // 触底函数
    hitBottom () {
      if (this.isEnd) return
      this.currentPage++
      this.getData()
    },
    // 搜索
    handleSearch () {
      this.initPage()
      this.getData()
    }
  }
}
</script>

<style scoped>
.search-bar {
  margin: 15px;
}
.list {
  background: #fff;
  margin: 15px;
  padding: 0 20px;
}
.list-item {
  width: 100%;
  padding: 10px 0;
}
.list-item > img {
  width: 75px;
  height: 75px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 10px;
}
.list-item + .list-item {
  border-top: 1px solid #eee;
}

.body {
  width: calc(100vw - 30px - 40px - 75px - 10px);
  height: 75px;
}
.title {
  font-size: 16px;
}
.content {
  width: 100%;
  font-size: 14px;
  color: #aaa;
}
.read-count > span:last-child, .favour-count > span:last-child {
  max-width: calc((100vw - 30px - 40px - 75px - 10px - 18px - 16px - 14px - 72px) / 2);
  margin-left: 2px;
}
.favour-count {
  margin: 0 5px;
}
.time {
  min-width: 72px;
}
</style>
